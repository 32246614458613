import React, { useState } from "react";
import {
  Container,
  Box,
  Paper,
  Grid,
  Typography,
  Breadcrumbs,
  Collapse,
  Button,
} from "@material-ui/core";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import Item from "./Item";
import { Link } from "gatsby";
import styles from "./Collection.module.scss";
import "@wordpress/block-library/build-style/style.css";
import { appURL, logoURL, createCdnURL } from "../config/app";
import Helmet from "react-helmet";

function RenderContent({ content }) {
  const [expand, setExpand] = useState(false);
  if (!content || content.length < 1) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        variant="outlined"
        square
        className={styles.mainPaper}
      >
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs={12} lg={8}>
            <Collapse collapsedHeight="350px" in={expand} timeout="auto">
              <Typography
                variant="body2"
                paragraph
                align="justify"
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              ></Typography>
            </Collapse>
          </Grid>

          <Grid container item direction="row" justify="center">
            <Grid>
              <Button
                size="small"
                onClick={() => setExpand(!expand)}
                variant="outlined"
                style={{ marginTop: "15px" }}
              >
                {expand === true ? "Hide" : "Read More"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

function Collection(props) {
  const { title, items, content,location } = props;

  const getSummary = (title) => {
    return `Latest ${title} prices  and specifications in Pakistan, Daily updates on local markets and online prices in Lahore, Peshawar, Islamabad , whatmobile, Daraz.pk, Homeshopping.pk, Telemart.pk, Priceoye.com.pk`;
  };

  const metaImage = (items) => {
    if (items.length > 0) {
      return createCdnURL("large", (items[0].image || items[0].cover_image));
    }
    return logoURL;
  };

  const url = location.pathname

  let metaTitle = `Latest ${title} Price & Specs in Pakistan`;

  return (
    <React.Fragment>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaTitle} />

        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@electronikspk" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={getSummary(title)} />
        <meta name="twitter:image" content={metaImage(items)} />

        <meta property="og:title" content={metaTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${appURL}${url}`} />
        <meta property="og:image" content={metaImage(items)} />
        <meta property="og:description" content={getSummary(title)} />

        <script type="application/ld+json">{JSON.stringify({
          name: title,
          url: `${appURL}/${url}`,
          image: metaImage(items),
          description: getSummary(title),
          slogan: metaTitle
        })}</script>
      </Helmet>

      <Box bgcolor="#ecf0f2" height="100%">
        <Container maxWidth={false}>
          <Paper
            square
            style={{
              padding: "10px",
              marginTop: "7px",
            }}
          >
            <Box className={styles.breadcrumb}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  color="inherit"
                  to={"/"}
                  style={{ textDecoration: "none", color: "#0a182d" }}
                >
                  Home
                </Link>
                <Typography color="inherit">{title}</Typography>
              </Breadcrumbs>
            </Box>
            <Box style={{ padding: "10px" }}>
              <Typography variant="h4">{title}</Typography>
            </Box>
            <RenderContent content={content} />
            <Box style={{ padding: "5px" }} className="shit">
              <Grid
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}
                item
                container
                xs={12}
                className="MuiGrid-spacing-xs-2-fix"
              >
                {(items || []).map((item, i) => (
                  <Grid item key={i} xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Item
                      url={item.url}
                      image={(item.image || item.cover_image)}
                      title={item.title}
                    ></Item>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default Collection;
