import React from "react";
import { Typography, Box } from "@material-ui/core";
import { Link } from "gatsby";
import Truncate from "react-truncate";
import genClasses from "../../css/main.module.scss";
import styles from "./Item.module.scss";
import { createCdnURL } from "../../config/app";
import LazyLoad from "react-lazyload";
import { appURL } from "../../config/app";

export default function Item(props) {
  const { title, url, image } = props;

  return (
    <Box className={styles.listing} fontWeight="fontWeightBold">
      <Box className={genClasses.tac}>
        <Link
          to={`${appURL}/${url}/`}
          style={{ textDecoration: "none" }}
          className={styles.prodBox}
        >
          <LazyLoad height={300}>
            <img
              loading="lazy"
              alt={title}
              src={createCdnURL("large", image)}
              width="100%"
            />
          </LazyLoad>
        </Link>
      </Box>
      <Link to={url} style={{ textDecoration: "none", color: "#0a182d" }}>
        <Typography className={styles.listingTitle}>
          <Truncate lines={2} ellipsis={<span>...</span>}>
            {title}
          </Truncate>
        </Typography>
      </Link>
    </Box>
  );
}
