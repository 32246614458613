import React from "react";
import CollectionPage from "../collection";
import Layout from "../components/Layout";

function Collection(props) {
  const { pageContext, location } = props;

  return (
    <Layout sideMenuCollections={pageContext.sideMenuCollections}>
      <CollectionPage
        title={pageContext.title}
        trendings={pageContext.trendings}
        items={pageContext.items}
        content={pageContext.content}
        location={location}
      />
    </Layout>
  );
}

export default Collection;
